import { AppStateStore } from '../stores/appStateStore';

export function appError(message) {
  AppStateStore.update((s) => {
    s.error = message;
  });
}

export function resetAppState() {
  AppStateStore.update((s) => {
    s.isSubscribed = false;
    s.isLoaded = false;
    s.error = undefined;
  });
}
