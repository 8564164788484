import React from 'react';
import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { HeaderWithColorModeSwitcher } from './ColorModeSwitcher';

export const Modal = (header, body, footer, isOpen, cancelRef, onClose) => {
  return (
    <ChakraModal
      size="lg"
      initialFocusRef={cancelRef}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay>
        <ModalContent>
          <HeaderWithColorModeSwitcher>{header}</HeaderWithColorModeSwitcher>

          <ModalBody>{body}</ModalBody>

          <ModalFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            {footer}
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </ChakraModal>
  );
};
