import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { MainView } from './views/MainView';
import { SignInView } from './views/SignIn';

import { useStoreState } from 'pullstate';
import { AppStateStore } from './stores/appStateStore';
import { useFirebaseManager } from './managers/firebaseManager';

function getGamePath(gameId, characterId) {
  return `/game/${gameId}/${characterId}`;
}

function useRequireCharacterExists(gameId, characterId) {
  const navigate = useNavigate();

  const { isSubscribed, isLoaded } = useStoreState(AppStateStore, (s) => ({
    isSubscribed: s.isSubscribed,
    isLoaded: s.isLoaded,
  }));

  useEffect(() => {
    if (isLoaded && !isSubscribed) {
      navigate(`/login/${gameId}/${characterId}`);
    }
  }, [navigate, isSubscribed, isLoaded, gameId, characterId]);
}

function Main() {
  return <MainView />;
}

function GamePage() {
  const { gameId } = useParams();

  useRequireCharacterExists(gameId);
  useFirebaseManager(gameId);

  return <MainView />;
}

function CharacterPage() {
  const { gameId, characterId } = useParams();

  useRequireCharacterExists(gameId, characterId);
  useFirebaseManager(gameId, characterId);

  return <MainView />;
}

function SignInPage() {
  const navigate = useNavigate();
  const { gameId, characterId } = useParams();
  const error = useStoreState(AppStateStore, (s) => s.error);

  return (
    <SignInView
      channel={gameId}
      character={characterId}
      onSubmit={(gameId, characterId) =>
        navigate(getGamePath(gameId, characterId))
      }
      error={error}
    />
  );
}

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="game/:gameId" element={<GamePage />} />
          <Route path="game/:gameId/:characterId" element={<CharacterPage />} />
          <Route path="login/:gameId/:characterId" element={<SignInPage />} />
          <Route path="login/:gameId/" element={<SignInPage />} />
          <Route path="*" element={<SignInPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
