import { appError } from '../actions/appStateActions';
import { MessageStore } from '../stores/messageStore';

export class MessageManager {
  subscriptions = [];

  unsubscribe() {
    this.subscriptions.forEach((unsub) => unsub());
    this.subscriptions = [];
  }

  subscribe(db, channel) {
    this.unsubscribe();

    MessageStore.update((s) => {
      s.channel = channel;
    });

    // update message ref
    let messagesRef = db.ref(`${channel}/messages`);
    messagesRef
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(`${channel} exists`);
        }

        // subscribe to firebase store
        messagesRef.on('value', function (snapshot) {
          if (snapshot.exists()) {
            MessageStore.update((s) => {
              s.messages = snapshot.val();
            });
          }
        });

        // when PullState store is updated, write changes to Firebase
        this.subscriptions.push(
          MessageStore.createReaction(
            (s) => s.messages,
            (messages, draft) => {
              if (!messagesRef) {
                return;
              }
              messagesRef.set(messages);
            }
          )
        );
        console.log(`Subscribed to ${channel} messages`);

        this.subscriptions.push(() => messagesRef.off());
      })
      .catch((e) => {
        appError(e.message);
      });
  }
}
