import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Input,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FilterableAnimatedGrid } from './Grid';
import { Card } from './Card';
import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import { arrayify } from '../functions/utils';
import { addAbilityMessage } from '../actions/messageActions';
import { Modal } from './Modal';

export function getAbilityText(ability) {
  return [ability.name || '', ability.description?.short || ''].join(' ');
}

export function NerveIcon(props) {
  const bg = useColorModeValue(`teal.500`, `teal.200`);
  const color = useColorModeValue('white', `gray.800`);

  return (
    <Tag
      size="sm"
      colorScheme="teal"
      borderRadius="full"
      background={bg}
      color={color}
      sx={{ transition: 'color 50ms;' }}
      {...props}
    >
      <b>N</b>
    </Tag>
  );
}

export function AbilityShort({ ability }) {
  return (
    <Box>
      <Stack d="inline" direction="row" marginRight={1}>
        {ability.nerve && <NerveIcon />}
        <Text as="b">{ability.name}</Text>
      </Stack>
      {ability.description?.short && '- '} {ability.description?.short}
    </Box>
  );
}

export function AbilityCard({ ability, ...rest }) {
  return (
    <Card {...rest}>
      <AbilityShort ability={ability} />
    </Card>
  );
}

export function AbilityOutcome({ label, outcome }) {
  return (
    <>
      <Text as="b">
        {label}
        {outcome.nerve && 'n'}
        {outcome.vetoable && 'x'}
      </Text>{' '}
      - {outcome.description}
    </>
  );
}

function OutcomeRadio({ label, outcome, ...rest }) {
  if (!outcome) {
    return null;
  }

  return (
    <Radio {...rest}>
      <AbilityOutcome label={label} outcome={outcome} />
    </Radio>
  );
}

function AbilityResolutionSelect({
  ability,
  value,
  setValue,
  selected,
  setSelected,
  isAlternative,
  ...rest
}) {
  return (
    <RadioGroup
      name="ability-resolution-select"
      onChange={setValue}
      value={value}
      {...rest}
    >
      <Stack>
        {Object.entries(ability.outcomes).map(([label, outcome]) => (
          <OutcomeRadio
            key={`${ability.name}-${label}`}
            value={label}
            label={label}
            outcome={outcome}
          />
        ))}
      </Stack>
    </RadioGroup>
  );
}

function AbilityResolutionConfirm({
  ability,
  value,
  setValue,
  selected,
  setSelected,
  isAlternative,
  ...rest
}) {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');

  let selectedOutcome = (
    <OutcomeRadio
      key={`${ability.name}-${value}`}
      value={value}
      label={value}
      outcome={ability.outcomes[value]}
    />
  );
  let otherOutcomes = [];
  Object.entries(ability.outcomes).forEach(([label, outcome]) => {
    if (label !== value) {
      otherOutcomes.push(
        <OutcomeRadio
          key={`${ability.name}-${label}`}
          value={label}
          label={label}
          outcome={outcome}
        />
      );
    }
  });

  return (
    <RadioGroup
      name="ability-resolution-confirm"
      onChange={setSelected}
      value={selected}
      {...rest}
    >
      <Stack>
        <Text as="i">You selected:</Text>
        <Box bgColor={bgColor} px={4} py={3} rounded="lg">
          {selectedOutcome}
        </Box>
        <Text as="i">Does it match? If not, select an option from below:</Text>
        <Stack>{otherOutcomes}</Stack>
        <Text as="i">
          If the option you chose has an 'x' next to it, you may choose to take
          a different course of action instead
        </Text>
      </Stack>
    </RadioGroup>
  );
}

function AbilityResolutionModal({ ability, isOpen, cancelRef, onClose }) {
  const character = useStoreState(CharacterStore, (s) => s.name);
  const [selected, setSelected] = useState(false);
  const [value, setValue] = React.useState(null);
  const [caption, setCaption] = React.useState('');
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');

  function handleClose() {
    setSelected(false);
    setValue(null);
    setCaption('');
    onClose();
  }

  const description = arrayify(
    ability?.description?.long || ability?.description?.short || null
  );

  const AbilityResolution = selected
    ? AbilityResolutionConfirm
    : AbilityResolutionSelect;

  const header = (
    <Stack
      direction="row"
      alignItems="center"
      padding={4}
      paddingBottom={0}
      marginX={3}
    >
      {ability.nerve && <NerveIcon marginBottom="2px" />}
      <ModalHeader flex="1" padding={0}>
        {ability.name}
      </ModalHeader>
    </Stack>
  );

  const body = (
    <Stack>
      {!selected && description && (
        <Stack bgColor={bgColor} px={4} py={3} rounded="lg">
          {description.map((paragraph, i) => (
            <Text key={`para-${i}`}>
              <i>{paragraph}</i>
            </Text>
          ))}
        </Stack>
      )}
      <AbilityResolution
        ability={ability}
        value={value}
        setValue={setValue}
        selected={selected}
        setSelected={setSelected}
      />
      <Center>
        <Input
          m={1}
          type="text"
          variant="outline"
          placeholder="Describe action"
          rounded="lg"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
        />
      </Center>
    </Stack>
  );

  const footer = !selected ? (
    <Button
      colorScheme="teal"
      isDisabled={!character || !value}
      onClick={() => {
        setSelected(value);
      }}
      ml={3}
    >
      Select
    </Button>
  ) : (
    <Button
      colorScheme="green"
      isDisabled={!character}
      onClick={() => {
        addAbilityMessage(character, ability.name, selected, caption);
        handleClose();
      }}
      ml={3}
    >
      Save
    </Button>
  );

  return Modal(header, body, footer, isOpen, cancelRef, handleClose);
}

export function AbilityCardWithResolution({ ability, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const cancelRef = React.useRef();

  if (!ability) {
    return null;
  }

  return (
    <>
      <AbilityCard ability={ability} onClick={onOpen} {...rest} />
      <AbilityResolutionModal
        ability={ability}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
      />
    </>
  );
}

export function FilterableAbilityCardGrid({ abilities, ...rest }) {
  if (!abilities) {
    return null;
  }

  const items = Object.entries(abilities || {}).reduce(
    (o, [key, ability]) => ({
      ...o,
      [key]: {
        filterOn: getAbilityText(ability),
        item: <AbilityCardWithResolution ability={ability} height="100%" />,
      },
    }),
    {}
  );

  return (
    <FilterableAnimatedGrid
      items={items}
      placeholder="Filter abilities"
      {...rest}
    />
  );
}
