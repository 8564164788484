import React from 'react';
import { Center, Stack, Text } from '@chakra-ui/react';
import { capitalize } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../../stores/gameplayStore';

export const quantumPowerMessageType = 'quantumPower';

export function QuantumPowerMessage(message, asMemory) {
  const power = useStoreState(
    GameplayStore,
    (s) => s['quantum abilities'][message.power]
  );

  if (!message || !power) {
    return;
  }

  return (
    <>
      <Stack spacing={0}>
        {!asMemory ? (
          <Text>
            <Text as="b">{message.character}</Text> {power.past}
          </Text>
        ) : (
          <Text>{capitalize(power.past)}</Text>
        )}
        <Center>
          <Text as="i">Your reality will pay a price.</Text>
        </Center>
        {message.caption && <Text as="i">{message.caption}</Text>}
      </Stack>
    </>
  );
}
