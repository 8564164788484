import React from 'react';
import {
  Box,
  Button,
  Input,
  ModalHeader,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../stores/gameplayStore';
import { Card } from './Card';
import { AnimatedGrid } from './Grid';
import { Modal } from './Modal';
import { CharacterStore } from '../stores/characterStore';
import { addQuantumPowerMessage } from '../actions/messageActions';

function QuantumPowerCard({ power, ...rest }) {
  return (
    <Card {...rest}>
      <Text>{power.name}</Text>
    </Card>
  );
}

function UseQuantumPowerModal({ power, isOpen, cancelRef, onClose }) {
  const character = useStoreState(CharacterStore, (s) => s.name);
  const [caption, setCaption] = React.useState('');
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');

  function handleClose() {
    setCaption('');
    onClose();
  }

  const header = <ModalHeader pb={0}>{power.name}</ModalHeader>;

  const body = (
    <Stack>
      <Box bgColor={bgColor} px={4} py={3} rounded="lg" textAlign="center">
        <Text as="i">Your reality will pay a price.</Text>
      </Box>
      <Input
        m={1}
        type="text"
        variant="outline"
        placeholder="Describe using your power"
        rounded="lg"
        value={caption}
        onChange={(e) => setCaption(e.target.value)}
      />
    </Stack>
  );

  const footer = (
    <Button
      colorScheme="green"
      isDisabled={!character}
      onClick={() => {
        addQuantumPowerMessage(character, power.key, caption);
        handleClose();
      }}
      ml={3}
    >
      Save
    </Button>
  );

  return Modal(header, body, footer, isOpen, cancelRef, handleClose);
}

export function QuantumPowerCardWithUseModal({ power, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const cancelRef = React.useRef();

  if (!power) {
    return null;
  }

  return (
    <>
      <QuantumPowerCard power={power} onClick={onOpen} {...rest} />
      <UseQuantumPowerModal
        power={power}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
      />
    </>
  );
}

export function QuantumPowerGrid(props) {
  const quantumPowers = useStoreState(
    GameplayStore,
    (s) => s['quantum abilities']
  );

  const items = Object.entries(quantumPowers).reduce(
    (o, [key, power]) => ({
      ...o,
      [key]: (
        <QuantumPowerCardWithUseModal
          power={{ ...power, key: key }}
          height="100%"
        />
      ),
    }),
    {}
  );

  return <AnimatedGrid items={items} {...props} />;
}
