import { useEffect, useState } from 'react';
import app from 'firebase/app';
// eslint-disable-next-line no-unused-vars
import database from 'firebase/database';

import { firebaseConfig } from '../config/firebaseConfig.js';

import { CharacterManager } from './characterManager.js';
import { GroupManager } from './groupManager.js';
import { MessageManager } from './messageManager.js';

app.initializeApp(firebaseConfig);

export const db = app.database();

export function useFirebaseManager(channel, character, allowCreation) {
  const [characterManager] = useState(() => new CharacterManager());
  const [groupManager] = useState(() => new GroupManager());
  const [messageManager] = useState(() => new MessageManager());

  useEffect(() => {
    if (channel) {
      messageManager.subscribe(db, channel);
    } else {
      messageManager.unsubscribe();
    }
    return () => {
      messageManager.unsubscribe();
    };
  }, [channel, messageManager]);

  useEffect(() => {
    if (channel) {
      groupManager.subscribe(db, channel);
    } else {
      groupManager.unsubscribe();
    }
    return () => {
      groupManager.unsubscribe();
    };
  }, [channel, groupManager]);

  useEffect(() => {
    if (channel && character) {
      characterManager.subscribe(db, channel, character, allowCreation);
    } else {
      characterManager.unsubscribe();
    }
    return () => {
      characterManager.unsubscribe();
    };
  }, [allowCreation, channel, character, characterManager]);
}
