import React from 'react';
import { Stack, useColorModeValue } from '@chakra-ui/react';

export function Card({ children, ...props }) {
  const shadowColor = useColorModeValue('0, 0, 0', '255, 255, 255');

  return (
    <Stack
      orientation="column"
      rounded="lg"
      p={4}
      spacing={2}
      sx={{
        boxShadow: `0px 1px 1px 0px rgba(${shadowColor}, .15),
                    0px 2px 2px 0px rgba(${shadowColor}, .15),
                    0px 4px 4px 0px rgba(${shadowColor}, .15),
                    0px 0px 8px 0px rgba(${shadowColor}, .15),
                    inset 0 1px 0 1px rgba(${shadowColor}, .13)`,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
}
