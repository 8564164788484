import { Store } from 'pullstate';

export const getInitialCharacterState = (name) => {
  return {
    name: name,
  };
};

export const CharacterStore = new Store({
  name: undefined,
});
