import React from 'react';
import {
  Box,
  Button,
  Center,
  ModalHeader,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { Modal } from './Modal';
import {
  addFulfillFaringMessage,
  addOfferFaringMessage,
} from '../actions/messageActions';
import { useCharacters } from '../actions/groupActions';

function Faring({ faring, withHover, ...rest }) {
  if (!faring) {
    return null;
  }

  return (
    <Box {...rest}>
      <Text _hover={withHover && { textDecoration: 'underline' }}>
        {faring}
      </Text>
    </Box>
  );
}

function FaringFulfillModal({ character, faring, isOpen, cancelRef, onClose }) {
  const [caption, setCaption] = React.useState('');
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');

  function handleClose() {
    setCaption('');
    onClose();
  }

  const header = <ModalHeader pb={0}>Fulfill Faring</ModalHeader>;
  const body = (
    <Stack>
      <Box bgColor={bgColor} px={4} py={3} rounded="lg">
        <Faring faring={faring} />
      </Box>

      <Center>
        <Textarea
          m={1}
          variant="outline"
          placeholder="Describe the new memory you create by fulfilling the faring"
          rounded="lg"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
        />
      </Center>
    </Stack>
  );

  const footer = (
    <Button
      colorScheme="green"
      isDisabled={!character}
      onClick={() => {
        addFulfillFaringMessage(character, faring, caption);
        handleClose();
      }}
      ml={3}
    >
      Save
    </Button>
  );

  return Modal(header, body, footer, isOpen, cancelRef, handleClose);
}

function FaringWithFulfillModal({ character, faring, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const cancelRef = React.useRef();

  if (!faring) {
    return null;
  }

  return (
    <>
      <Faring faring={faring} withHover onClick={onOpen} {...rest} />
      <FaringFulfillModal
        character={character}
        faring={faring}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
      />
    </>
  );
}

function FaringOfferModal({ character, faring, isOpen, cancelRef, onClose }) {
  const characters = useCharacters([character]);
  const [otherCharacter, setOtherCharacter] = React.useState('');
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');

  const otherCharacterOptions = Object.keys(characters);

  function handleClose() {
    setOtherCharacter('');
    onClose();
  }

  const header = <ModalHeader pb={0}>Offer Faring</ModalHeader>;

  const body = (
    <Stack>
      <Box bgColor={bgColor} px={4} py={3} rounded="lg">
        <Faring faring={faring} />
      </Box>

      <Center>
        <Select
          placeholder="Select who to offer this faring to"
          onChange={(e) => setOtherCharacter(e.target.value)}
        >
          {otherCharacterOptions.map((char) => (
            <option key={char} value={char}>
              {char}
            </option>
          ))}
        </Select>
      </Center>
    </Stack>
  );

  const footer = (
    <Button
      colorScheme="green"
      isDisabled={!character || !otherCharacter}
      onClick={() => {
        addOfferFaringMessage(character, otherCharacter, faring, '');
        handleClose();
      }}
      ml={3}
    >
      Save
    </Button>
  );

  return Modal(header, body, footer, isOpen, cancelRef, handleClose);
}

function FaringWithOfferModal({ character, faring, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const cancelRef = React.useRef();

  if (!faring) {
    return null;
  }

  return (
    <>
      <Faring faring={faring} withHover onClick={onOpen} {...rest} />
      <FaringOfferModal
        character={character}
        faring={faring}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
      />
    </>
  );
}

export function FaringOfferBox({ character, farings }) {
  if (!farings) {
    return null;
  }

  return (
    <SimpleGrid spacing={4} columns={[1, 1, 2, 2, 3, 4]}>
      {farings.map((faring, i) => (
        <FaringWithOfferModal
          key={`faring-${i}`}
          character={character}
          faring={faring}
        />
      ))}
    </SimpleGrid>
  );
}

export function FaringFulfillBox({ character, farings }) {
  if (!farings) {
    return null;
  }

  return (
    <SimpleGrid spacing={4} columns={[1, 1, 2, 2, 3, 4]}>
      {farings.map((faring, i) => (
        <FaringWithFulfillModal
          key={`faring-${i}`}
          character={character}
          faring={faring}
        />
      ))}
    </SimpleGrid>
  );
}
