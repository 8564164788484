import { useStoreState } from 'pullstate';
import { GroupStore } from '../stores/groupStore';

export function useCharacters(excludeCharacters) {
  const characters = useStoreState(GroupStore, (s) => s.characters);

  const characterNames = Object.entries(characters)
    ?.filter(
      ([_, character]) =>
        !excludeCharacters || !excludeCharacters.includes(character?.name)
    )
    ?.reduce(
      (obj, [characterId, character]) => ({
        ...obj,
        [character.name]: characterId,
      }),
      {}
    );

  return characterNames;
}

export function useFarings(excludeCharacters) {
  const characters = useStoreState(GroupStore, (s) => s.characters);

  const faringsByCharacter = Object.values(characters || {})
    ?.filter(
      (character) =>
        !excludeCharacters || !excludeCharacters.includes(character?.name)
    )
    ?.reduce(
      (obj, character) => ({
        ...obj,
        [character.name]: character.farings,
      }),
      {}
    );

  return faringsByCharacter;
}
