import React from 'react';
import { Box, Heading, Stack, useColorModeValue } from '@chakra-ui/react';

import {
  useAbilityMessages,
  useFulfillFaringMessages,
} from '../actions/messageActions';
import { MessageStack } from './Message';

export function MemoryBox({ character, ...rest }) {
  const faringMessages = useFulfillFaringMessages(character);
  const abilityMessages = useAbilityMessages(character);
  const color = useColorModeValue(`teal.500`, `teal.200`);

  return (
    <Stack>
      <Box>
        <Heading size="sm" color={color} sx={{ transition: 'color 50ms;' }}>
          Memories
        </Heading>
        <MessageStack messages={faringMessages} asMemory {...rest} />
      </Box>
      <Box>
        <Heading size="sm" color={color} sx={{ transition: 'color 50ms;' }}>
          I Have Used My Abilities
        </Heading>
        <MessageStack messages={abilityMessages} asMemory {...rest} />
      </Box>
    </Stack>
  );
}
