import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Spacer,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

import { Tooltip } from './Tooltip';

export const ColorModeSwitcher = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const label = `Switch to ${text} mode`;

  return (
    <Tooltip label={label}>
      <IconButton
        size="md"
        fontSize="lg"
        aria-label={label}
        variant="ghost"
        color="current"
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        {...props}
      />
    </Tooltip>
  );
};

export function HeaderWithColorModeSwitcher({ children }) {
  return (
    <Flex alignItems="baseline">
      <Box>{children}</Box>
      <Spacer />
      <ColorModeSwitcher />
    </Flex>
  );
}
