import { uuidv4 } from '../functions/utils';

import { abilityMessageType } from '../components/messages/AbilityMessage';
import {
  fulfillFaringMessageType,
  offerFaringMessageType,
} from '../components/messages/FaringMessage';
import { quantumPowerMessageType } from '../components/messages/QuantumPowerMessage';
import { sessionNoteMessageType } from '../components/messages/SessionNoteMessage';

import { useStoreState } from 'pullstate';
import { MessageStore } from '../stores/messageStore';

export function useMessages(types, characters) {
  const messages = useStoreState(MessageStore, (s) => s.messages);

  return messages
    ?.filter(
      (message) => !types || (message.type && types.includes(message.type))
    )
    .filter(
      (message) =>
        !characters ||
        (message.character && characters.includes(message.character))
    );
}

export function addMessage(message) {
  const messageId = uuidv4();
  const timestamp = Date.now();
  MessageStore.update((s) => {
    s.messages ?? (s.messages = []);
    if (typeof message === 'object') {
      s.messages.push({ ...message, id: messageId, timestamp: timestamp });
    } else {
      s.messages.push({ id: messageId, timestamp: timestamp, text: message });
    }
  });
  return messageId;
}

export function removeMessage(messageId) {
  MessageStore.update((s) => {
    s.messages ?? (s.messages = []);
    s.messages = s.messages.filter((message) => message.id !== messageId);
  });
}

export function addAbilityMessage(character, abilityName, outcome, caption) {
  return addMessage({
    type: abilityMessageType,
    character: character,
    ability: abilityName,
    outcome: outcome,
    caption: caption,
  });
}

export function useAbilityMessages(character) {
  return useMessages([abilityMessageType], [character]);
}

export function addFulfillFaringMessage(character, faring, caption) {
  return addMessage({
    type: fulfillFaringMessageType,
    character: character,
    faring: faring,
    caption: caption,
  });
}

export function useFulfillFaringMessages(character) {
  return useMessages([fulfillFaringMessageType], [character]);
}

export function addOfferFaringMessage(character, toCharacter, faring, caption) {
  return addMessage({
    type: offerFaringMessageType,
    character: character,
    toCharacter: toCharacter,
    faring: faring,
    caption: caption,
  });
}

export function addQuantumPowerMessage(character, power, caption) {
  return addMessage({
    type: quantumPowerMessageType,
    character: character,
    power: power,
    caption: caption,
  });
}

export function addSessionNoteMessage(character, text) {
  return addMessage({
    type: sessionNoteMessageType,
    character: character,
    text: text,
  });
}
