import { Store } from 'pullstate';

export const getInitialAppState = () => {
  return {
    isSubscribed: false,
    isLoaded: false,
    error: undefined,
  };
};

export const AppStateStore = new Store(getInitialAppState);
