import { appError } from '../actions/appStateActions';
import { GroupStore } from '../stores/groupStore.js';

export class GroupManager {
  subscriptions = [];

  unsubscribe() {
    this.subscriptions.forEach((unsub) => unsub());
    this.subscriptions = [];
  }

  subscribe(db, channel) {
    this.unsubscribe();

    GroupStore.update((s) => {
      s.channel = channel;
    });

    // update message ref
    let groupRef = db.ref(`${channel}`);
    groupRef
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(`${channel} exists`);
        }

        // subscribe to firebase store
        groupRef.child('characters').on('value', function (snapshot) {
          if (snapshot.exists()) {
            GroupStore.update((s) => {
              s.characters = snapshot.val();
            });
          }
        });
        groupRef.child('messages').on('value', function (snapshot) {
          if (snapshot.exists()) {
            GroupStore.update((s) => {
              s.messages = snapshot.val();
            });
          }
        });

        // when PullState store is updated, write changes to Firebase
        /* Keeps getting rid of characters??
        this.subscriptions.push(
          GroupStore.createReaction(
            (s) => s.characters,
            (characters, draft) => {
              if (!groupRef) {
                return;
              }
              console.log(characters);
              groupRef.child('characters').set(characters);
            }
          )
        );
        this.subscriptions.push(
          GroupStore.createReaction(
            (s) => s.messages,
            (messages, draft) => {
              if (!groupRef) {
                return;
              }
              console.log(messages);
              groupRef.child('messages').set(messages);
            }
          )
        );
        */
        console.log(`Subscribed to ${channel} messages`);

        this.subscriptions.push(() => groupRef.off());
      })
      .catch((e) => {
        appError(e.message);
      });
  }
}
