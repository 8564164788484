import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  CloseButton,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { HeaderWithColorModeSwitcher } from './ColorModeSwitcher';
import { AbilityMessage, abilityMessageType } from './messages/AbilityMessage';
import {
  FulfillFaringMessage,
  fulfillFaringMessageType,
  OfferFaringMessage,
  offerFaringMessageType,
} from './messages/FaringMessage';
import {
  SessionNoteMessage,
  sessionNoteMessageType,
} from './messages/SessionNoteMessage';
import {
  QuantumPowerMessage,
  quantumPowerMessageType,
} from './messages/QuantumPowerMessage';

import {
  addSessionNoteMessage,
  removeMessage,
  useMessages,
} from '../actions/messageActions';
import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';

const MessageMap = {
  [abilityMessageType]: AbilityMessage,
  [fulfillFaringMessageType]: FulfillFaringMessage,
  [offerFaringMessageType]: OfferFaringMessage,
  [quantumPowerMessageType]: QuantumPowerMessage,
  [sessionNoteMessageType]: SessionNoteMessage,
};

function MessageContent(message, asMemory) {
  if (!message) {
    return;
  }
  if (MessageMap[message.type]) {
    return MessageMap[message.type](message, asMemory);
  } else {
    return <Text>{message.character}</Text>;
  }
}

export function Message({ id, message, asMemory, ...rest }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');
  const content = MessageContent(message, asMemory);
  if (!content) {
    return null;
  }

  return (
    <>
      <Box bg={bgColor} rounded="lg" m={2} py={2} px={3} {...rest}>
        <CloseButton float="right" size="sm" onClick={() => setIsOpen(true)} />
        {content}
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <HeaderWithColorModeSwitcher>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" pb={0}>
                Remove Message
              </AlertDialogHeader>
            </HeaderWithColorModeSwitcher>

            <AlertDialogBody>
              <Box bg={bgColor} rounded="lg" m={2} py={2} px={3} {...rest}>
                {MessageContent(message)}
              </Box>
              Are you sure you want to remove this message? You can't undo this
              action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  removeMessage(id);
                  onClose();
                }}
                ml={3}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

function MessageForm() {
  const [note, setNote] = useState('');
  const characterName = useStoreState(CharacterStore, (s) => s?.name);
  const isLoaded = characterName !== undefined;

  function handleSubmit(event) {
    event.preventDefault();
    if (!note) {
      return;
    }
    addSessionNoteMessage(characterName, note);
    setNote('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <HStack m={1} p={1}>
        <Input
          type="text"
          variant="filled"
          placeholder="Add session note"
          rounded="lg"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <Button
          type="submit"
          colorScheme="teal"
          isDisabled={!isLoaded || !note}
        >
          Submit
        </Button>
      </HStack>
    </form>
  );
}

export function MessageStack({ messages, asMemory }) {
  if (!messages) {
    return null;
  }

  return (
    <>
      {messages
        .slice(0)
        .reverse()
        .map((message) => (
          <AnimatePresence key={`animate-${message.id}`}>
            <motion.div
              key={`motion-${message.id}`}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
            >
              <Message
                key={message.id}
                id={message.id}
                message={message}
                asMemory={asMemory}
              />
            </motion.div>
          </AnimatePresence>
        ))}
    </>
  );
}

export function MessageBox() {
  const messages = useMessages();

  return (
    <Box className="messagebox">
      <MessageForm />
      <MessageStack messages={messages} />
    </Box>
  );
}
