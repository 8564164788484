import React from 'react';
import { Text } from '@chakra-ui/react';

export const sessionNoteMessageType = 'note';

export function SessionNoteMessage(message) {
  if (!message) {
    return;
  }

  return (
    <>
      <Text>
        {message.character && (
          <>
            <Text as="b">{message.character}</Text>
            {': '}
          </>
        )}
        {message.text}
      </Text>
    </>
  );
}
