import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';

import { AbilityOutcome } from '../Ability';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../../stores/gameplayStore';

export const abilityMessageType = 'ability';

export function AbilityMessage(message, asMemory) {
  const outcome = useStoreState(
    GameplayStore,
    (s) => s.abilities[message.ability].outcomes[message.outcome]
  );

  if (!message) {
    return;
  }

  return (
    <>
      <Stack spacing={0}>
        <Box>
          {!asMemory && (
            <>
              <Text as="b">{message.character}</Text> used{' '}
            </>
          )}
          <Text as="b">{message.ability}</Text>
        </Box>
        <Box>
          <AbilityOutcome label={message.outcome} outcome={outcome} />
        </Box>
        {message.caption && <Text as="i">{message.caption}</Text>}
      </Stack>
    </>
  );
}
