import React from 'react';
import { Box, Center, Stack, Text } from '@chakra-ui/react';

export const fulfillFaringMessageType = 'fulfill';

export function FulfillFaringMessage(message, asMemory) {
  if (!message) {
    return;
  }

  return (
    <>
      <Stack spacing={0}>
        <Box>
          {!asMemory ? (
            <>
              <Text as="b">{message.character}</Text> fulfilled a faring:
              <Center>
                <Text as="i">{message.faring}</Text>
              </Center>
            </>
          ) : (
            <Text as="b">{message.faring}</Text>
          )}
        </Box>
        {message.caption && <Text as="i">{message.caption}</Text>}
      </Stack>
    </>
  );
}

export const offerFaringMessageType = 'offer';

export function OfferFaringMessage(message, asMemory) {
  if (!message) {
    return;
  }

  return (
    <>
      <Stack spacing={0}>
        <Box>
          <Text>
            {!asMemory && (
              <>
                <Text as="b">{message.character}</Text> offered the faring{' '}
              </>
            )}
            <Text as="i">"{message.faring}"</Text> to {message.toCharacter}
          </Text>
        </Box>
        {message.caption && <Text as="i">{message.caption}</Text>}
      </Stack>
    </>
  );
}
