import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { MessageBox } from '../components/Message';

import { CharacterStore } from '../stores/characterStore';
import { GameplayStore } from '../stores/gameplayStore';
import { useStoreState } from 'pullstate';
import { FaringFulfillBox, FaringOfferBox } from '../components/Faring';
import { QuantumPowerGrid } from '../components/QuantumPower';
import { FilterableAbilityCardGrid } from '../components/Ability';
import { MemoryBox } from '../components/Memory';
import { useFarings } from '../actions/groupActions';

export function MainView() {
  const abilities = useStoreState(GameplayStore, (s) => s.abilities);
  const { characterName, nerve, farings } = useStoreState(
    CharacterStore,
    (s) => ({
      characterName: s.name,
      nerve: s.nerve,
      farings: s.farings,
    })
  );
  const groupFarings = Object.values(
    useFarings(characterName && [characterName])
  )?.reduce((obj, faring) => [...obj, ...faring], []);

  const characterAbilities = Object.entries(abilities || {}).reduce(
    (o, [key, value]) =>
      nerve && nerve.includes(key)
        ? { ...o, [key]: { ...value, nerve: true } }
        : { ...o, [key]: value },
    {}
  );
  return (
    <>
      <Flex flexDir="column" alignItems="center" p={3}>
        <Stack
          direction={{ base: 'column-reverse', md: 'row' }}
          alignItems="center"
          position="absolute"
          right="0"
          top="0"
          spacing={0}
        >
          <ColorModeSwitcher
            _hover={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
          />
        </Stack>
        <Heading size="md">{characterName}</Heading>
      </Flex>
      <Flex flexWrap="wrap" justifyContent="center">
        <Box></Box>
        <Box flex="1 1 500px">
          <Stack m={5} spacing={5}>
            {farings && (
              <Box>
                <Heading size="md" mb={3}>
                  Farings to Offer Others
                </Heading>
                <FaringOfferBox character={characterName} farings={farings} />
              </Box>
            )}
            {groupFarings && groupFarings.length > 0 && (
              <Box>
                <Heading size="md" mb={3}>
                  Farings in Play
                </Heading>
                <FaringFulfillBox
                  character={characterName}
                  farings={groupFarings}
                />
              </Box>
            )}
            <Box>
              <Heading size="md" mb={3}>
                Abilities
              </Heading>
              <FilterableAbilityCardGrid abilities={characterAbilities} />
            </Box>
            <Box>
              <Heading size="md" mb={3}>
                Quantum Powers
              </Heading>
              <QuantumPowerGrid character={characterName} />
            </Box>
          </Stack>
        </Box>
        <Box flex="1 1 330px" maxWidth="500px">
          <Tabs
            isFitted
            variant="enclosed-colored"
            colorScheme="teal"
            rounded="lg"
          >
            <TabList>
              <Tab>Session</Tab>
              <Tab>{characterName && `${characterName}'s `} Memories</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box maxHeight="87vh;" overflow="auto">
                  <MessageBox />
                </Box>
              </TabPanel>
              <TabPanel>
                <Box maxHeight="87vh;" overflow="auto">
                  <MemoryBox character={characterName} />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </>
  );
}
