import { appError, resetAppState } from '../actions/appStateActions';
import { AppStateStore } from '../stores/appStateStore';
import {
  CharacterStore,
  getInitialCharacterState,
} from '../stores/characterStore';

export class CharacterManager {
  subscriptions = [];

  unsubscribe() {
    this.subscriptions.forEach((unsub) => unsub());
    this.subscriptions = [];
  }

  subscribe(db, channel, character, createIfNonexistent) {
    this.unsubscribe();

    resetAppState();

    let characterRef = null;

    // update character ref
    // query for a character of that name
    db.ref(`${channel}/characters/`)
      .orderByChild('name')
      .equalTo(character)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(`${character} exists`);
          snapshot.forEach(function (childSnapshot) {
            characterRef = childSnapshot.ref;
          });
        } else if (createIfNonexistent) {
          // if character with name does not exist, create a new one
          characterRef = db.ref(channel).child('characters').push().ref;
          characterRef.set(getInitialCharacterState(character));
        } else {
          // TODO: update app state instead of erroring
          throw new Error(`Unable to find ${character} in channel ${channel}`);
        }

        // subscribe to firebase store
        characterRef.on('value', function (snapshot) {
          if (snapshot.exists()) {
            CharacterStore.replace(snapshot.val());
          }
        });

        // when PullState store is updated, write changes to Firebase
        this.subscriptions.push(
          CharacterStore.createReaction(
            (s) => s,
            (s, draft) => {
              if (!characterRef) {
                return;
              }
              characterRef.update(draft);
            }
          )
        );
        console.log(`Subscribed to ${character}`);

        this.subscriptions.push(() => characterRef.off());

        AppStateStore.update((s) => {
          s.isSubscribed = !!characterRef;
        });
      })
      .catch((e) => {
        appError(e.message);
      })
      .finally(() => {
        AppStateStore.update((s) => {
          s.isLoaded = true;
        });
      });
  }
}
