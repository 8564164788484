export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function decapitalize(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function arrayify(item) {
  if (Array.isArray(item)) {
    return item;
  } else if (typeof text === 'string') {
    return [item];
  }
  return null;
}

export function stringify(text) {
  if (Array.isArray(text)) {
    return text.join('\n   ');
  } else if (typeof text === 'string') {
    return text;
  }
  return null;
}
