import React, { useState } from 'react';
import { Box, SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import { ClearableInput } from '../components/ClearableInput';

export function AnimatedGrid({ items, ...rest }) {
  const minChildWidthPx = useBreakpointValue({ base: 300, sm: 400 });
  if (!items) {
    return null;
  }

  return (
    <SimpleGrid spacing={4} minChildWidth={`${minChildWidthPx}px`} {...rest}>
      {Object.entries(items).map(([key, item]) => (
        <AnimatePresence key={`animate-${key}`}>
          <motion.div
            key={key}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 20, opacity: 0 }}
          >
            <motion.div
              whileHover={{ transform: 'translate(0, -2%)' }}
              style={{ height: '100%' }}
            >
              {item}
            </motion.div>
          </motion.div>
        </AnimatePresence>
      ))}
    </SimpleGrid>
  );
}

function strContains(string, substring, caseSensitive = false) {
  if (!string || !substring) {
    return false;
  }
  if (caseSensitive) {
    return string.includes(substring);
  }
  return string.toLocaleLowerCase().includes(substring.toLocaleLowerCase());
}

export function FilterableAnimatedGrid({
  items,
  placeholder = 'Filter items',
  isCaseSensitive,
  ...rest
}) {
  const [filterValue, setFilterValue] = useState('');
  if (!items) {
    return null;
  }

  let filtered_items = Object.entries(items)
    .filter(
      ([_, item]) => !filterValue || strContains(item.filterOn, filterValue)
    )
    .reduce((obj, [key, value]) => {
      return { ...obj, [key]: value.item };
    }, {});

  return (
    <Stack>
      <Box mb={3}>
        <ClearableInput
          value={filterValue}
          onChange={setFilterValue}
          placeholder={placeholder}
        />
      </Box>
      <AnimatedGrid items={filtered_items} {...rest} />
    </Stack>
  );
}
