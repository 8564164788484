import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react';

import { ColorModeSwitcher } from '../components/ColorModeSwitcher';

export function SignInView({
  channel: channelName,
  character: characterName,
  onSubmit = () => {},
  error,
}) {
  const [tempChannelName, setTempChannelName] = useState(channelName);
  const [tempCharacterName, setTempCharacterName] = useState(characterName);
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    onSubmit(tempChannelName, tempCharacterName);
  }

  return (
    <VStack>
      <ColorModeSwitcher />
      <Box _hover={{ color: 'high.400' }}>
        <Heading m="15px"></Heading>
      </Box>
      <form onSubmit={handleSubmit}>
        <VStack maxWidth="225px" my="20px">
          <Input
            type="text"
            style={{ textAlign: 'center' }}
            name="channel"
            rounded="lg"
            placeholder="Channel Name"
            value={
              tempChannelName != null ? tempChannelName : channelName || ''
            }
            onChange={(e) => setTempChannelName(e.target.value)}
          />
          <Input
            type="text"
            style={{ textAlign: 'center' }}
            name="character name"
            rounded="lg"
            placeholder="Character Name"
            value={
              tempCharacterName != null
                ? tempCharacterName
                : characterName || ''
            }
            onChange={(e) => setTempCharacterName(e.target.value)}
          />
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Sign In
          </Button>
          <FormControl isInvalid={error}>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
          {error && (
            <Button
              type="submit"
              m={5}
              colorScheme="green"
              isLoading={isLoading}
            >
              Create New Character
            </Button>
          )}
        </VStack>
      </form>
    </VStack>
  );
}
